/* Import the same fonts as the main application */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200..800&display=swap');

/* General layout and background */
.info-page-container {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	background: #030712; /* Dark background to match the main app */
	color: #ffffff;
	padding: 20px;
	font-family: 'Bricolage Grotesque', sans-serif;
	position: relative;
	overflow: hidden; /* Prevent SVG blobs from creating scrollbars */
}

/* SVG background decorations */
.info-page-container svg {
	position: absolute;
	filter: blur(100px);
	z-index: 0;
	height: 600px;
	opacity: 0.3;
}

.info-page-container .blob-left-top {
	top: 0;
	left: 0;
	transform: translate(-100px, -100px);
}

.info-page-container .blob-center {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

/* Form container styling */
.form-wrapper {
	background: rgba(3, 7, 18, 0.5); /* Dark background with transparency */
	backdrop-filter: blur(20px);
	border-radius: 12px;
	padding: 2.5rem;
	box-shadow: 0 8px 30px rgba(0, 0, 0, 0.6);
	width: 100%;
	max-width: 500px;
	position: relative;
	z-index: 1;
	border: 1px solid rgba(83, 62, 149, 0.3); /* Purple border matching brand color */
}

/* Title styling */
.form-title {
	font-size: 2rem;
	text-align: center;
	margin-bottom: 1.5rem;
	color: #fff;
	letter-spacing: 0.5px;
	font-weight: 600;
}

/* Form styling */
.form {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
}

/* Group styling for inputs and labels */
.form-group {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

/* Label styling */
.form-label {
	font-weight: 500;
	color: #d1d5db;
	letter-spacing: 0.5px;
	font-size: 0.95rem;
}

/* Input fields */
.form-input {
	padding: 0.85rem;
	border-radius: 8px;
	border: 1px solid rgba(83, 62, 149, 0.5);
	background: rgba(3, 7, 18, 0.8);
	color: #ffffff;
	outline: none;
	transition: all 0.3s ease;
	font-family: 'Bricolage Grotesque', sans-serif;
}

/* Focus effect for input fields */
.form-input:focus {
	border-color: #784fff;
	box-shadow: 0 0 0 2px rgba(120, 79, 255, 0.2);
}

/* Button styling */
.form-button {
	padding: 0.85rem;
	background: linear-gradient(90deg, #533e95 2.34%, #784fff 100.78%);
	color: #ffffff;
	border: none;
	border-radius: 8px;
	font-weight: 600;
	font-size: 1rem;
	cursor: pointer;
	transition: transform 0.2s ease;
	margin-top: 0.5rem;
	font-family: 'Bricolage Grotesque', sans-serif;
}

/* Error text styling */
.error-text {
	color: #f87171;
	font-size: 0.85rem;
	margin-top: 0.25rem;
}

/* Referral info styling */
.referral-info {
	background: rgba(83, 62, 149, 0.2);
	color: #ffffff;
	padding: 10px 15px;
	margin-bottom: 20px;
	border-radius: 8px;
	border-left: 4px solid #784fff;
	font-size: 0.9rem;
	display: flex;
	align-items: center;
	gap: 8px;
}

.referral-info span {
	font-weight: 600;
	color: #784fff;
}

/* Responsive adjustments */
@media (max-width: 600px) {
	.form-title {
		font-size: 1.5rem;
	}

	.form-wrapper {
		padding: 1.5rem;
	}
}
