/* Import the same fonts as the main application */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200..800&display=swap');

/* Footer styling */
.footer {
	width: 100%;
	background-color: #030712;
	border-top: 1px solid rgba(83, 62, 149, 0.3);
	color: #ffffff;
	padding: 3rem 0 1.5rem;
	margin-top: auto;
	font-family: 'Bricolage Grotesque', sans-serif;
}

.footer-container {
	max-width: 1200px;
	margin: 0 auto;
	padding: 0 2rem;
}

.footer-content {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 2rem;
	margin-bottom: 2rem;
}

/* Footer logo section */
.footer-logo h3 {
	font-size: 1.5rem;
	font-weight: 600;
	margin-bottom: 0.5rem;
	background: linear-gradient(90deg, #533e95 2.34%, #784fff 100.78%);
	-webkit-background-clip: text;
	background-clip: text;
	color: transparent;
	display: inline-block;
}

.footer-logo p {
	color: #9ca3af;
	font-size: 0.9rem;
	max-width: 250px;
}

/* Footer navigation links */
.footer-nav {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.footer-link {
	display: flex;
	align-items: center;
	color: #d1d5db;
	text-decoration: none;
	transition: color 0.3s ease;
	font-weight: 400;
	gap: 0.5rem;
}

.footer-link:hover {
	color: #ffffff;
}

.footer-link svg {
	color: #784fff;
}

/* Footer contact section */
.footer-contact {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

/* Payment information section */
.payment-info {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 0.5rem;
	margin-bottom: 2rem;
	padding: 1rem;
	border-radius: 8px;
}

.payment-info p {
	color: #9ca3af;
	font-size: 0.85rem;
	margin: 0;
}

.payment-logo {
	transition: opacity 0.3s ease;
}

.payment-logo:hover {
	opacity: 0.8;
}

.payment-logo img {
	max-width: 440px;
	width: 100%;
	object-fit: contain;
}

/* Footer bottom section */
.footer-bottom {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-top: 1.5rem;
	border-top: 1px solid rgba(83, 62, 149, 0.2);
}

.copyright p {
	font-size: 0.875rem;
	color: #6b7280;
}

.footer-social {
	display: flex;
	gap: 1rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
	.footer-content {
		grid-template-columns: 1fr;
		gap: 2rem;
		text-align: center;
	}

	.footer-logo p {
		max-width: none;
		margin: 0 auto;
	}

	.footer-nav,
	.footer-contact {
		align-items: center;
	}

	.footer-bottom {
		flex-direction: column;
		gap: 1rem;
	}
}

@media (max-width: 480px) {
	.footer {
		padding: 2rem 0 1rem;
	}

	.footer-container {
		padding: 0 1rem;
	}
}
