/* Import the same fonts as the main application */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200..800&display=swap');

.privacy-policy {
	min-height: 100vh;
	background-color: #030712; /* Dark background to match the main app */
	padding: 2rem 0;
	font-family: 'Bricolage Grotesque', sans-serif;
	color: #ffffff;
	position: relative;
	overflow: hidden; /* Prevent SVG blobs from creating scrollbars */
}

/* SVG background decorations */
.privacy-policy svg {
	position: absolute;
	filter: blur(100px);
	z-index: 0;
	height: 600px;
	opacity: 0.3;
}

.privacy-policy .blob-left-top {
	top: 0;
	left: 0;
	transform: translate(-100px, -100px);
}

.privacy-policy .blob-center {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.privacy-container {
	max-width: 800px;
	margin: 0 auto;
	padding: 2.5rem;
	background: rgba(3, 7, 18, 0.5); /* Dark background with transparency */
	border-radius: 12px;
	box-shadow: 0 8px 30px rgba(0, 0, 0, 0.6);
	position: relative;
	z-index: 1;
	border: 1px solid rgba(83, 62, 149, 0.3); /* Purple border matching brand color */
	backdrop-filter: blur(20px);
	margin-bottom: 3rem;
}

.privacy-policy h1 {
	color: #ffffff;
	text-align: center;
	margin-bottom: 2rem;
	font-size: 2.5rem;
	font-weight: 700;
	background: linear-gradient(90deg, #533e95 2.34%, #784fff 100.78%);
	-webkit-background-clip: text;
	background-clip: text;
	color: transparent;
	display: inline-block;
	width: 100%;
}

.privacy-policy h2 {
	color: #ffffff;
	margin: 1.5rem 0 1rem;
	font-size: 1.5rem;
	border-left: 4px solid #784fff;
	padding-left: 0.75rem;
}

.privacy-policy section {
	margin-bottom: 2rem;
}

.privacy-policy p {
	line-height: 1.6;
	color: #d1d5db;
	margin-bottom: 1rem;
}

.privacy-policy ul {
	list-style-type: disc;
	margin-left: 2rem;
	color: #d1d5db;
}

.privacy-policy li {
	margin-bottom: 0.5rem;
	line-height: 1.6;
}

.privacy-policy strong {
	color: #ffffff;
	font-weight: 600;
}

/* Contact section styling */
.contact-section {
	background: rgba(83, 62, 149, 0.1);
	padding: 1.5rem;
	border-radius: 8px;
	margin-top: 2.5rem;
}

.contact-info {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	margin-top: 1rem;
}

.contact-item {
	display: flex;
	align-items: center;
	gap: 0.75rem;
	color: #d1d5db;
}

.contact-item svg {
	color: #784fff;
}

.policy-footer {
	margin-top: 3rem;
	text-align: center;
	color: #9ca3af;
	font-size: 0.9rem;
}

@media (max-width: 768px) {
	.privacy-container {
		padding: 1.5rem;
		margin: 0 1rem 2rem;
	}

	.privacy-policy h1 {
		font-size: 2rem;
	}

	.privacy-policy h2 {
		font-size: 1.25rem;
	}
}

@media (max-width: 480px) {
	.privacy-container {
		padding: 1.25rem;
	}

	.contact-info {
		flex-direction: column;
	}
}
