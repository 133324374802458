@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200..800&display=swap');
*,
*:after,
*:before {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	height: 100%;
	min-height: 100%;
	scroll-behavior: smooth;
}

.container {
	max-width: 1400px !important;
}

body {
	overflow-x: hidden !important;
	font-family: 'Bricolage Grotesque', sans-serif;
	background: #030712;
}

#preloader {
	background: #030712;
	height: 100vh;
	width: 100%;
	position: fixed;
	z-index: 9999;
	display: flex;
	justify-content: center;
	align-items: center;
}

.loader {
	width: 48px;
	height: 48px;
	border-radius: 50%;
	display: inline-block;
	border-top: 3px solid #fff;
	border-right: 3px solid transparent;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
.subheader {
	background: linear-gradient(90deg, #533e95 2.34%, #784fff 100.78%);
	width: 100%;
	display: flex;
	justify-content: center;
	top: 0px;
	position: sticky;
	height: 30px;
	align-items: center;
	z-index: 999;
}
.subheader h6 {
	color: #fff;
	text-transform: uppercase;
	letter-spacing: 2px;
	font-weight: 600;
	font-size: 16px;
	text-align: center;
}

.headline {
	width: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}
.headline h2 {
	font-size: 48px;
	font-weight: 700;
	line-height: 50.4px;
	color: #fff;
	text-align: center;
}
.headline h2 span {
	background: linear-gradient(90deg, #533e95 2.34%, #784fff 100.78%);
	color: transparent;
	-webkit-background-clip: text;
	background-clip: text;
}
.headline p {
	color: #fff;
	text-align: center;
	font-size: 22px;
	line-height: 31.2px;
	width: 80%;
	font-weight: 600;
	padding-top: 2rem;
}
.headline p span {
	background: linear-gradient(90deg, #533e95 2.34%, #784fff 100.78%);
	font-weight: bold;
	color: transparent;
	-webkit-background-clip: text;
	background-clip: text;
}
.headline p img {
	transform: translateY(20%);
	margin-right: 2px;
	height: 30px;
}

.fa-star {
	color: gold;
}
@media (max-width: 600px) {
	.headline h2 {
		font-size: 34px;
		text-decoration: underline;
	}
}
.landing {
	height: 100dvh;
	padding: 50px 0px;
	width: 100vw;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: left;
	gap: 50px;
}
.landing svg {
	position: absolute;
	filter: blur(100px);
	z-index: -909;
	height: 600px;
	opacity: 0.3;
}
.landing .blob-left-top {
	top: 0px;
	left: 0px;
	transform: translate(-100px, -100px);
}
.landing .blob-center {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.landing .svg-bottom-right {
	right: 0px;
	bottom: 0px;
	transform: translate(150px, 200px);
}
.landing .cards {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	place-items: center;
	gap: 40px;
}
.landing .cards .card-first {
	justify-content: left;
	border: 1px solid #c3c3c3;
}
.landing .cards .card-second {
	justify-content: center;
	border: 1px solid #533e95;
}
.landing .cards .card-third {
	justify-content: center;
	border: 1px solid #ea61ff;
}
.landing .cards .card {
	border-radius: 8px;
	height: 450px;
	width: 340px;
	display: flex;
	align-items: flex-end;
	transition: 350ms ease;
	position: relative;
}
.landing .cards .card img {
	height: 400px;
}
.landing .cards .card .person {
	transition: 350ms ease;
}
.landing .cards .card .title {
	position: absolute;
	left: 50%;
	bottom: 0px;
	transform: translate(-50%, 150%);
}
.landing .cards .card .title h3 {
	color: #fff;
	text-transform: uppercase;
	font-weight: normal;
	transition: 350ms ease;
}
.landing .cards .card:hover {
	cursor: pointer;
}
.landing .cards .card:hover h3 {
	letter-spacing: 4px;
	font-weight: normal;
}

header {
	display: flex;
	height: 100dvh;
	align-items: center;
	justify-content: space-evenly;
	margin: 0rem auto 0;
	gap: 1.25rem;
	align-items: center;
	position: relative;
}
header h3 {
	font-size: 16px;
	text-align: center;
	font-weight: 600;
	line-height: 17.5px;
	background: linear-gradient(90deg, #533e95 2.34%, #784fff 100.78%);
	color: transparent;
	-webkit-background-clip: text;
	background-clip: text;
	letter-spacing: 2px;
	text-transform: uppercase;
	width: 80%;
}
header .text-hero {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding-left: 20%;
	padding-top: 10%;
}
header .text-hero h1 {
	text-align: left;
}
header .text-hero h3 {
	text-align: left;
}
header .text-hero h1 {
	font-size: 60px;
	font-weight: 700;
	line-height: 67.2px;
	letter-spacing: -0.64px;
	margin-top: 20px;
	color: #fff;
	width: 100%;
}
header .text-hero h1 span {
	padding: 0px 30px;
	position: relative;
	margin: 0px 0px 40px 15px !important;
}
header .text-hero h1 span:after {
	content: '';
	transform: rotate(-1deg) translateY(-50%);
	background: linear-gradient(90deg, #533e95 2.34%, #784fff 100.78%);
	width: 100%;
	height: 80%;
	top: 50%;
	position: absolute;
	left: 0px;
	right: 0px;
	z-index: -11;
}
header .text-hero p {
	color: #fff;
	font-weight: 400;
	margin: 35px 0px 45px;
	opacity: 0.8;
	width: 70%;
	font-size: 1.125rem;
	line-height: 1.625rem;
	text-align: left;
}
header .text-hero p span {
	color: #ffffff;
	text-decoration: underline;
}
header .text-hero .form-CTA {
	width: 100%;
	display: flex;
	flex-direction: column;
}
header .text-hero .form-CTA a {
	text-decoration: none;
}
header .text-hero .form-CTA button {
	width: 420px;
	height: 70px;
	border-radius: 3px;
	background: linear-gradient(90deg, #533e95 2.34%, #784fff 100.78%);
	color: #fff;
	font-weight: 400;
	font-size: 16px;
	border: none;
	letter-spacing: -0.06px;
	font-family: 'Bricolage Grotesque', sans-serif;
	gap: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	transition: 350ms ease;
	text-decoration: none !important;
}
header .text-hero .form-CTA button .fa-solid {
	transition: 150ms ease;
}
header .text-hero .form-CTA button:hover .fa-solid {
	scale: 1.2;
	transform: rotate(13deg);
}
header .text-hero .form-CTA .button-info {
	margin: 10px 0px !important;
	font-weight: 400;
	font-size: 16px;
}
header .text-hero .form-CTA .button-info span {
	color: #4e950c;
	text-decoration: none;
}
header .text-hero .form-CTA .reviews-stars {
	display: flex;
	z-index: 11;
	align-items: flex-start;
	justify-content: flex-start;
	width: 100%;
	width: 100%;
	height: 100%;
	margin-top: 30px;
	gap: 20px;
}
header .text-hero .form-CTA .reviews-stars .avatars-stars {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
header .text-hero .form-CTA .reviews-stars .avatars-stars .img-first {
	margin-left: 0px !important;
}
header .text-hero .form-CTA .reviews-stars .avatars-stars img {
	margin-left: -20px;
	height: 50px;
	width: 50px;
	border-radius: 50%;
	border: 4px solid #030712;
}
header .text-hero .form-CTA .reviews-stars .review-stars {
	display: flex;
	flex-direction: column;
	gap: 5px;
}
header .text-hero .form-CTA .reviews-stars .stars-reviews {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 3px;
}
header .text-hero .form-CTA .reviews-stars .stars-reviews .fa-solid {
	color: #eab308;
	font-size: 17px;
}
header .text-hero .form-CTA .reviews-stars .stars-info-reviews {
	color: #acacac;
	font-weight: 200;
	font-style: italic;
}
header .text-hero .form-CTA .reviews-stars .stars-info-reviews span {
	color: #c8c8c8;
	font-weight: 400;
}
header .image-hero {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
}
header .image-hero img {
	height: 100%;
	filter: drop-shadow(0px 0px 70px #3b2879);
}

@media (max-width: 1240px) {
	header {
		flex-direction: column;
		padding: 50px 100px;
	}
	header .text-hero {
		padding: 0px !important;
		display: flex;
		justify-content: center;
	}
	header .image-hero {
		display: flex;
		align-items: center !important;
		justify-content: flex-start !important;
		display: none;
	}
	header .image-hero img {
		height: auto;
		width: 100%;
		max-width: 320px !important;
	}
}
@media (max-width: 720px) {
	header {
		padding: 0px 20px !important;
		flex-wrap: wrap;
	}
	header .text-hero {
		justify-content: center !important;
		align-items: center;
	}
	header .text-hero h1 {
		font-size: 38px !important;
		line-height: 1;
		text-align: center;
	}
	header .text-hero h3 {
		text-align: center;
	}
	header .text-hero p {
		font-size: 16px !important;
		text-align: center;
		width: 90%;
	}
	header .form-CTA {
		height: auto;
		align-items: center;
	}
	header .form-CTA button,
	header .form-CTA a {
		height: 50px !important;
		width: 260px !important;
		margin-bottom: 10px;
	}
	header .form-CTA .button-info {
		width: 100%;
		text-align: center;
		font-size: 14px !important;
	}
	header .form-CTA .reviews-stars {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
	}
	header .form-CTA .reviews-stars .avatars-stars {
		width: 100%;
		display: flex;
		justify-content: center !important;
		align-items: center;
	}
	header .form-CTA .reviews-stars .review-stars {
		width: 100%;
		display: flex;
		justify-content: center !important;
	}
	header .form-CTA .reviews-stars .review-stars .stars-reviews,
	header .form-CTA .reviews-stars .review-stars .stars-info-reviews {
		width: 100%;
		justify-content: center !important;
		text-align: center;
	}
}
@media (max-width: 420px) {
	header {
		padding: 0px 10px !important;
		flex-wrap: wrap;
	}
	header .text-hero {
		justify-content: center !important;
		align-items: center;
	}
	header .text-hero h1 {
		font-size: 38px !important;
		line-height: 1;
		text-align: center;
	}
	header .text-hero h3 {
		text-align: center;
	}
	header .text-hero p {
		font-size: 16px !important;
		text-align: center;
	}
	header .form-CTA {
		height: auto;
		align-items: center;
	}
	header .form-CTA button,
	header .form-CTA a {
		height: 50px !important;
		width: 80% !important;
		display: flex;
		justify-content: center;
		margin-bottom: 10px;
	}
	header .form-CTA .button-info {
		width: 100%;
		text-align: center;
	}
	header .form-CTA .reviews-stars {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
	}
	header .form-CTA .reviews-stars .avatars-stars {
		width: 100%;
		display: flex;
		justify-content: center !important;
		align-items: center;
	}
	header .form-CTA .reviews-stars .review-stars {
		width: 100%;
		display: flex;
		justify-content: center !important;
	}
	header .form-CTA .reviews-stars .review-stars .stars-reviews,
	header .form-CTA .reviews-stars .review-stars .stars-info-reviews {
		width: 100%;
		justify-content: center !important;
		text-align: center;
	}
}
.pricing {
	min-height: 100vh;
	width: 95%;
	max-width: 1400px;
	padding-bottom: 100px;
	padding-top: 100px;
	margin: 0 auto 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
.pricing .ebooks {
	height: 100%;
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	place-items: center;
	padding: 60px 10px;
	gap: 10px;
}
@media (max-width: 1100px) {
	.pricing .ebooks {
		min-height: 100vh;
		height: auto;
		display: grid;
		grid-template-columns: 1fr;
		gap: 40px;
	}
	.pricing .ebooks .e-book-main {
		scale: 1;
	}
}
.pricing .e-book-main {
	position: relative;
	scale: 1.02;
	box-shadow: 1px 1px 15px #784fff;
}
.pricing .e-book-main .information-popular {
	position: absolute;
	left: 50%;
	top: 0px;
	width: 240px;
	height: 40px;
	transform: translate(-50%, -50%);
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 40px;
	background: #784fff;
	color: #fff;
}
.pricing .e-book-main .information-popular h4 {
	font-weight: normal;
}
.pricing .e-book {
	border: 1px solid #fff;
	padding-top: 2.5rem;
	padding: 30px;
	margin-top: 2.5rem;
	background-color: rgba(22, 27, 34, 0.5);
	border-radius: 20px;
	border-width: 1px;
	border-color: #784fff;
	max-width: 428px;
	width: 90%;
	height: 100%;
}
.pricing .e-book .background-title {
	border-radius: 0.375rem;
	background-color: rgb(22, 27, 34);
	width: 200px;
	margin: 0 auto 10px;
	padding: 10px;
}
.pricing .e-book .title {
	background: linear-gradient(90deg, #533e95 2.34%, #784fff 100.78%);
	-webkit-background-clip: text;
	background-clip: text;
	color: transparent;
}
.pricing .e-book h3 {
	text-align: center;
	font-size: 20px;
	font-weight: 600;
	line-height: 26px;
	background: linear-gradient(90deg, #ff7170 -50.34%, #ffe57f 100.78%);
	background-clip: border-box;
	-webkit-background-clip: text;
	background-clip: text;
	color: transparent;
}
.pricing .e-book .pricetag2 {
	font-size: 34px;
	font-weight: 600;
	transform: translateY(10px);
	opacity: 0.9;
	text-decoration: line-through;
	color: #fff;
	text-align: center;
	margin-top: 20px;
}
.pricing .e-book h2 {
	line-height: 67.2px;
	font-size: 64px;
	font-weight: 700;
	letter-spacing: -1px;
	color: #fff;
	text-align: center;
}
.pricing .e-book .info {
	color: #bbc8e6;
	text-align: center;
	font-size: 12px;
}
.pricing .e-book .feature-title {
	margin-top: 50px;
	margin-bottom: 15px;
	text-align: left;
	font-weight: 500;
	text-transform: uppercase;
	color: #fff;
	font-size: 20px;
	font-weight: 600;
	line-height: 26px;
}
.pricing .e-book .feature {
	margin-top: 10px;
	width: 100%;
	display: flex;
	justify-content: left;
	align-items: center;
}
.pricing .e-book .feature .star-2 {
	background: linear-gradient(180deg, #feda15, #fcb320) !important;
	color: transparent !important;
	-webkit-background-clip: text !important;
	background-clip: text !important;
}
.pricing .e-book .feature .fa-solid {
	background: linear-gradient(90deg, #533e95 2.34%, #784fff 100.78%);
	color: transparent;
	-webkit-background-clip: text;
	background-clip: text;
	margin-right: 10px;
	display: inline-block;
}
.pricing .e-book .feature p {
	color: #e5e7eb;
	font-size: 14px;
	font-weight: 500;
	display: inline-block;
	text-align: left;
	margin-left: 10px;
}
.pricing .e-book a {
	text-decoration: none;
}
.pricing .e-book button {
	margin: 0 auto;
	background: linear-gradient(90deg, #533e95 2.34%, #784fff 100.78%);
	border: none;
	text-decoration: none;
	background-color: rgba(0, 0, 0, 0);
	width: 240px;
	margin-top: 1.75rem;
	color: rgb(248, 250, 252);
	padding-top: 18px;
	padding-bottom: 18px;
	border-radius: 0.375rem;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: 350ms;
	font-size: 15px;
	text-transform: uppercase;
	font-weight: 500;
}
.pricing .e-book button:hover {
	cursor: pointer;
	scale: 1.05;
}

.benefits {
	min-height: 70vh;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
.benefits .container {
	min-height: 30vh;
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	padding-top: 50px;
	place-items: center;
}
.benefits .container .benefit {
	margin-top: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.benefits .container .benefit p {
	color: #bbc8e6;
	text-align: center;
	width: 75%;
}
.benefits .container .benefit p span {
	color: #fff;
}
.benefits .container .benefit .fa-solid {
	font-size: 28px;
	background: linear-gradient(90deg, #533e95 2.34%, #784fff 100.78%);
	border-radius: 50%;
	width: 30px;
	height: 30px;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 15px;
	text-align: center;
	color: #fff;
}
.benefits .container .benefit .fa-sold {
	font-size: 28px;
	background: linear-gradient(90deg, rgb(255, 162, 0) 2.34%, rgb(255, 191, 0) 100.78%);
	border-radius: 50%;
	width: 30px;
	height: 30px;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 15px;
	text-align: center;
	color: #fff;
}

.benefits .container .benefit h2 {
	font-size: 25px;
	font-weight: 600;
	margin: 10px 0px 20px;
	background: linear-gradient(90deg, #533e95 2.34%, #784fff 100.78%);
	color: transparent;
	-webkit-background-clip: text;
	background-clip: text;
	text-align: center;
}
.benefits .container .benefit .h2-second {
	background: linear-gradient(180deg, #feda15, #fcb320) !important;
	color: transparent !important;
	-webkit-background-clip: text !important;
	background-clip: text !important;
}

@media (max-width: 900px) {
	.benefits .container {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr 1fr 1fr;
		place-content: center;
		place-items: center;
	}
	.benefits .container .benefit {
		margin: 60px 0px;
	}
	.benefits .container .benefit p {
		width: 50%;
	}
}
@media (max-width: 900px) {
	.benefits .container .benefit p {
		width: 80%;
	}
}
.how-we-work {
	height: 100vh;
	width: 100%;
}
.how-we-work .container {
	height: 100%;
	width: 80%;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;
}
.how-we-work .container .text {
	width: 50%;
}
.how-we-work .container .text h4 {
	color: #fff;
}
.how-we-work .container .text hr {
	color: #bbc8e6;
}
.how-we-work .container .text p {
	color: #bbc8e6;
}

.program {
	min-height: 100vh;
	width: 100vw;
}
.program .container {
	width: 90%;
	margin: 0 auto;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	padding: 30px;
	gap: 30px;
}
.program .container .step {
	width: 100%;
	border: 1px solid #bbc8e6;
	padding: 25px;
	border-radius: 14px;
	color: #fff;
}
.program .container .step h2 {
	text-transform: uppercase;
	font-weight: 600;
}
.program .container .step h3 {
	font-weight: 500;
}
.program .container .step h6 {
	font-weight: normal;
	font-size: 16px;
	margin-bottom: 10px;
}
.program .container .step p {
	color: #bbc8e6;
	font-weight: 300;
}
.program .container .step p a {
	color: linear-gradient(90deg, #533e95 2.34%, #784fff 100.78%);
}

@media (max-width: 1200px) {
	.program .container {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
}
@media (max-width: 800px) {
	.program .container {
		display: grid;
		grid-template-columns: 1fr;
	}
}
.animation-with {
	width: 240px;
	height: 80px;
	margin-top: 40px;
	overflow: hidden;
}
.animation-with .with {
	width: 240px;
	height: 60px;
	border-radius: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.animation-with .with1 {
	background: #c3c3c3;
}
.animation-with .with1 h4 {
	color: #333333;
}
.animation-with .with2 {
	background: #533e95;
	color: #fff;
}
.animation-with .with3 {
	background: #ea61ff;
	color: #fff;
}
.animation-with .withs {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	animation: withAnimation 9s infinite;
	gap: 60px;
}

@keyframes withAnimation {
	0% {
		transform: translateY(0px);
	}
	14% {
		transform: translateY(0%);
	}
	27% {
		transform: translateY(-120px);
	}
	44% {
		transform: translateY(-120px);
	}
	55% {
		transform: translateY(-240px);
	}
	68% {
		transform: translateY(-240px);
	}
	80% {
		transform: translateY(-360px);
	}
	95% {
		transform: translateY(-360px);
	}
	100% {
		transform: translateY(-360px);
	}
}
footer {
	background: #140b5c;
	width: 100%;
	bottom: 0;
	left: 0;
	position: relative;
}

footer .content {
	max-width: 1250px;
	margin: auto;
	padding: 30px 40px 40px 40px;
}

footer .content .top {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 50px;
}

.content .top .logo-details {
	color: #fff;
	font-size: 30px;
	display: flex;
	justify-content: left;
	align-items: center;
	gap: 10px;
}
.content .top .logo-details img {
	height: 50px;
	border-radius: 50%;
}

.content .top .media-icons {
	display: flex;
}

.content .top .media-icons a {
	height: 40px;
	width: 40px;
	margin: 0 8px;
	border-radius: 50%;
	text-align: center;
	line-height: 40px;
	color: #fff;
	font-size: 17px;
	text-decoration: none;
	transition: all 0.4s ease;
}

.top .media-icons a:nth-child(1) {
	background: #4267b2;
}

.top .media-icons a:nth-child(1):hover {
	color: #4267b2;
	background: #fff;
}

.top .media-icons a:nth-child(2) {
	background: #1da1f2;
}

.top .media-icons a:nth-child(2):hover {
	color: #1da1f2;
	background: #fff;
}

.top .media-icons a:nth-child(3) {
	background: #e1306c;
}

.top .media-icons a:nth-child(3):hover {
	color: #e1306c;
	background: #fff;
}

.top .media-icons a:nth-child(4) {
	background: #0077b5;
}

.top .media-icons a:nth-child(4):hover {
	color: #0077b5;
	background: #fff;
}

.top .media-icons a:nth-child(5) {
	background: #ff0000;
}

.top .media-icons a:nth-child(5):hover {
	color: #ff0000;
	background: #fff;
}

footer .content .link-boxes {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

footer .content .link-boxes .box {
	width: calc(20% - 10px);
}

.content .link-boxes .box .link_name {
	color: #fff;
	font-size: 18px;
	font-weight: 400;
	margin-bottom: 10px;
	position: relative;
}

.link-boxes .box .link_name::before {
	content: '';
	position: absolute;
	left: 0;
	bottom: -2px;
	height: 2px;
	width: 35px;
	background: #fff;
}

.content .link-boxes .box li {
	margin: 6px 0;
	list-style: none;
	color: #e3e3e3;
	font-size: 14px;
}

.content .link-boxes .box li a {
	color: #fff;
	font-size: 14px;
	font-weight: 400;
	text-decoration: none;
	opacity: 0.8;
	transition: all 0.4s ease;
}

.content .link-boxes .box li a:hover {
	opacity: 1;
	text-decoration: underline;
}

.content .link-boxes .input-box {
	margin-right: 55px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.content .link-boxes .input-box img {
	height: auto;
	width: 100%;
}

.link-boxes .input-box input {
	height: 40px;
	width: calc(100% + 55px);
	outline: none;
	border: 2px solid #afafb6;
	background: #140b5c;
	border-radius: 4px;
	padding: 0 15px;
	font-size: 15px;
	color: #fff;
	margin-top: 5px;
}

.link-boxes .input-box input::-moz-placeholder {
	color: #afafb6;
	font-size: 16px;
}

.link-boxes .input-box input::placeholder {
	color: #afafb6;
	font-size: 16px;
}

.link-boxes .input-box input[type='button'] {
	background: #fff;
	color: #140b5c;
	border: none;
	font-size: 18px;
	font-weight: 500;
	margin: 4px 0;
	opacity: 0.8;
	cursor: pointer;
	transition: all 0.4s ease;
}

.input-box input[type='button']:hover {
	opacity: 1;
}

footer .bottom-details {
	width: 100%;
	background: #0f0844;
}

footer .bottom-details .bottom_text {
	max-width: 1250px;
	margin: auto;
	padding: 20px 40px;
	display: flex;
	justify-content: space-between;
}

.bottom-details .bottom_text span,
.bottom-details .bottom_text a {
	font-size: 14px;
	font-weight: 300;
	color: #fff;
	opacity: 0.8;
	text-decoration: none;
}

.bottom-details .bottom_text a:hover {
	opacity: 1;
	text-decoration: underline;
}

.bottom-details .bottom_text a {
	margin-right: 10px;
}

@media (max-width: 900px) {
	footer .content .link-boxes {
		flex-wrap: wrap;
	}
	footer .content .link-boxes .input-box {
		width: 40%;
		margin-top: 10px;
	}
}
@media (max-width: 700px) {
	footer {
		position: relative;
	}
	.content .top .logo-details {
		font-size: 26px;
	}
	.content .top .media-icons a {
		height: 35px;
		width: 35px;
		font-size: 14px;
		line-height: 35px;
	}
	footer .content .link-boxes .box {
		width: calc(33.3333333333% - 10px);
	}
	footer .content .link-boxes .input-box {
		width: 60%;
	}
	.bottom-details .bottom_text span,
	.bottom-details .bottom_text a {
		font-size: 12px;
	}
}
@media (max-width: 520px) {
	footer::before {
		top: 145px;
	}
	footer .content .top {
		flex-direction: column;
	}
	.content .top .media-icons {
		margin-top: 16px;
	}
	footer .content .link-boxes .box {
		width: calc(50% - 10px);
	}
	footer .content .link-boxes .input-box {
		width: 100%;
	}
}
.container-regulamin {
	min-height: 100vh;
	color: #fff;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 30px;
	gap: 20px;
}

.container-regulamin h2 {
	text-align: center;
	font-size: 60px;
}

.container-regulamin h4 {
	font-weight: normal;
	font-size: 40px;
}

.container-regulamin p {
	font-weight: 300;
	font-size: 21px;
}

.reviews-section {
	min-height: 50vh;
	margin: 0 auto;
	width: 100%;
	max-width: 1020px;
	width: 100%;
	/* Gdy najechany jest konkretny obraz */
}
.reviews-section .container-reviews {
	width: 100%;
	margin-top: 100px;
	-moz-column-count: 2;
	column-count: 2;
}
.reviews-section .container-reviews img {
	height: 100%;
	box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.75);
	border-radius: 4px;
	transition: 350ms ease;
}
.reviews-section .container-reviews img:hover {
	transform: scale(1.05);
	cursor: text;
	box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.95);
}
.reviews-section .container-reviews:hover img {
	filter: blur(3px) opacity(0.7);
}
.reviews-section .container-reviews img:hover {
	filter: none;
}

@media (max-width: 1080px) {
	.reviews-section .container-reviews {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		gap: 10px;
	}
}
@media (max-width: 550px) {
	.reviews-section .container-reviews img {
		width: 90%;
	}
}
@media (max-width: 440px) {
	header {
		min-height: 100vh !important;
		height: auto;
	}
	h1 b {
		display: flex;
		flex-direction: column;
		line-height: 1;
	}

	header .text-hero h1 span {
		margin-bottom: 0px !important;
	}
}
.graduationhat {
	height: 20px;
}
.email-form-overlay {
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}
